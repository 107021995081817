import { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Col, Row } from "react-bootstrap";
import EmailVar from "./EmailVar";
import LiCSSsLiveEditor from "../../lib/LiCSSsLiveEditor/LiCSSsLiveEditor";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

const TemplateType={
    "serma-reply":"SerMa申込返信",
    "serma-email":"SerMaメール送信"
};
export default function Templates(){
    const BCrumb={'CurrentText':'テンプレート','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState(false);
    const Subject=useRef();
    const [Visible,setVisible]=useState(false);
    const [Alert,setAlert]=useState();
    const [Type,setType]=useState(0);
    const [Content,setContent]=useState();
    useEffect(()=>{
        if(document.getElementById('editor')){
            ReactDOM.render(<></>,document.getElementById('editor'));
        }
    },[])
    function changeType(e){
        if(document.getElementById('editor')){
            ReactDOM.render(<></>,document.getElementById('editor'));
        }
        setVisible(true);
        setType(e.target.value)
        if(e.target.value!=0){
            let send={
                'url':'v1/admin/template/'+e.target.value,
                'method':'GET'
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                    Subject.current.value=data['payloads']['contents']['subject'];
                    ReactDOM.render(<LiCSSsLiveEditor defaultValue={data['payloads']['contents']['body']} setContent={setContent} />,document.getElementById('editor'));
                }else{
                    setItem(false);
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setVisible(false);
                setItem(false);
                console.log(error);
            });
        }else{
            setItem(false);
            setVisible(false);
        }
    }
    function doEntry(){
        setVisible(true);
        let send={
            'url':'v1/admin/template/'+Type,
            'method':'PUT',
            'body':{
                contents:{
                    subject:Subject.current.value,
                    body:Content
                }
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["テンプレートを登録しました。"]});
                ReactDOM.render(<></>,document.getElementById('editor'));
                setItem(false);
                setContent();
                setType(0);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            setItem(false);
            console.log(error);
        });
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <Row>
                <Col sm="auto">
                    <p>
                        テンプレートを選択してください。
                    </p>
                </Col>
                <Col sm="auto">
                    <select className="form-select" value={Type} onChange={changeType}>
                        <option value={0} label="選択してください" />{Object.keys(TemplateType).map(template=>
                        <option value={template} key={template}>{TemplateType[template]}</option>
                            )}
                    </select>
                </Col>
            </Row>{Item ?
            <div>
                <div className="input-group">
                    <span className="input-group-text">件名</span>
                    <input type="text" id="TemplateSubject" className="form-control" placeholder="件名" ref={Subject} required />
                </div>
                <div className="input-group">
                    <span className="input-group-text">本文</span>
                    <div id="editor" className="form-control"></div>
                </div>{
                    //SerMaパラメータ
                Type.indexOf('serma')>-1 ? <EmailVar />:""}
                <button type="button" className="btn btn-primary mt-2" onClick={doEntry}>登録</button>
            </div>:""}
        </div>
    )
}