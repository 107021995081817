import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import { getUser } from "../..";
import SendJson from "../../controller/SendJson";

export default function Password({setCrumbItem}){
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(false);
    useEffect(()=>{
        setCrumbItem(['パスワード変更',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
    },[]);
    function doSubmit(){
		setVisible(true);
        const user=getUser();
        let send={
            'url':'v1/user/'+user['UserId'],
            'method':'PUT',
            'body':{}
        }
        send['body']=SendJson(document.getElementsByClassName('js-data'));
        Fetch(send).then(data=>{
            if(data['result']){
                setVisible(false);
                let form=document.getElementsByClassName('js-data');
                for(let i=0;i<form.length;++i){
                    form[i].value="";
                }
                setAlert({'type':'success','style':true,'msgs':["パスワードを変更しました。"]});
            }else{
                setVisible(false);

                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)
            setVisible(false);
        });
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    現在のパスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="CurrentPassword" data-label="現在のパスワード" className="form-control js-data" placeholder="現在のパスワード" autoComplete="current-password" required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    新しいパスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="password" data-label="パスワード" className="form-control js-data" placeholder="新しいパスワード" autoComplete="new-password" required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    確認用パスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="password_confirmation" data-label="確認用パスワード" className="form-control js-data" placeholder="確認用パスワード" autoComplete="confirm-password" required />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={doSubmit}>登録</button>
                </div>
            </div>
        </>
    );
}