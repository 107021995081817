import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import { Domain } from "../controller/keys";
import LineBtn from "./common/LineBtn";

export default function Profile(){
    return (
        <Routes>
            <Route path="teacher/:id" element={<Teacher />} />
            <Route path=":id" element={<User />} />
        </Routes>
    );
}
function User(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Profile,setProfile]=useState(false);
    useEffect(()=>{
        let send={
            url:'v1/getProfile/U'+id,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setProfile(data['payloads']);
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    },[])
    return (
        <div>
            <Loading visible={Visible} />{Profile ?
            <>
                <h3>
                    {Profile['UserName']}
                </h3>
                <UserProfile Profile={Profile} />
            </>:""
        }</div>
    );
}
function UserProfile({Profile}){
    return (
        <>
            <img src={Profile['icon']['link']} className="files__icon-lg" />
            <div>
                {Profile['introduction']}
            </div>
            <div>
                ホームページ：{(Profile['hp']['public'] && Profile['hp']['link']) ?
                <a href={Profile['hp']['link']} target="_blank">
                    {Profile['hp']['link']}
                </a>:"非公開"}
            </div>
            <div>
                Twitter：{(Profile['twitter']['public'] && Profile['twitter']['username']) ?
                <a href={"https://twitter.com/"+Profile['twitter']['username']} target="_blank">
                    @{Profile['twitter']['username']}
                </a>:"非公開"}
            </div>
        </>
    );
}
function Teacher(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Profile,setProfile]=useState(false);
    useEffect(()=>{
        let send={
            url:'v1/getProfile/T'+id,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setProfile(data['payloads']);
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    },[])
    return (
        <div>{(Profile && Profile['profiles'] && Profile['courses']) ?
            <>
                <p>
                    {Profile['courses']['CourseName']}講師
                </p>
                <h3 className="mt-0 mb-3">
                    {Profile['profiles']['UserName']}
                </h3>
                <h4 className="my-2">
                    {Profile['courses']['CourseName']}に関するお問い合わせ
                </h4>
                <a href={Domain("course",Profile['courses']['CourseId']+"/syllabus")}>
                    {Profile['courses']['CourseName']}ページ
                </a>
                <div className="alert alert-info">{Profile['courses']['link']!="" && 
                    <div className="row">
                        <div className="col-2">
                            ホームページ
                        </div>
                        <div className="col-auto">
                            <a href={Profile['courses']['link']} target="_blank">
                                {Profile['courses']['link']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['email']!="" && 
                    <div className="row">
                        <div className="col-2">
                            email
                        </div>
                        <div className="col-auto">
                            <a href={"email:"+Profile['courses']['email']} target="_blank">
                                {Profile['courses']['email']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['tel']!="" && 
                    <div className="row">
                        <div className="col-2">
                            Tel
                        </div>
                        <div className="col-auto">
                            <a href={"tel:"+Profile['courses']['tel']} target="_blank">
                                {Profile['courses']['tel']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['line']!="" && 
                    <div className="row">
                        <div className="col-2">
                            LINE
                        </div>
                        <div className="col-auto">
                            <LineBtn Link={Profile['courses']['line']} Text="ともだち追加" target="_blank" />
                        </div>
                    </div>}
                </div>
                <div>
                    <h4 className="my-2">
                        ユーザープロフィール
                    </h4>
                    <UserProfile Profile={Profile['profiles']} />
                </div>
            </>:""
        }</div>
    );
}