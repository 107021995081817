import { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Link, Routes, Route } from "react-router-dom";
import Crumb from "../controller/Crumb";
import Info from "./account/Info";
import InfoDetail from "./account/InfoDetail";
import Password from "./account/Password";
import Profile from "./account/Profile";
import Sns from "./account/Sns";
import Friend from "./account/Friend";
import Invitation from "./account/Invitation";
import Payment from "./account/Payment";
import PaymentDetail from "./account/PaymentDetail";
import Support from "./account/Support";

export default function Account(){
    const [CrumbItem,setCrumbItem]=useState(['アカウント',[
        {
            index:0,
            href:"/",
            text:"ホーム"
        }
    ]]);
    return (
        <>
            <Crumb CurrentText={CrumbItem[0]} items={CrumbItem[1]} />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Link to="info" className="nav-link">お知らせ</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="profile" className="nav-link">プロフィール設定</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="password" className="nav-link">パスワード設定</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="sns" className="nav-link">SNS連携</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="friend" className="nav-link">フレンド申請</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="invitation" className="nav-link">お友達招待</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="payment" className="nav-link">決済</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="support" className="nav-link">サポート</Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Routes>
                                <Route path="info" element={<Info setCrumbItem={setCrumbItem} />} />
                                <Route path="info/:id" element={<InfoDetail setCrumbItem={setCrumbItem} />} />
                                <Route path="profile" element={<Profile setCrumbItem={setCrumbItem} />} />
                                <Route path="password" element={<Password setCrumbItem={setCrumbItem} />} />
                                <Route path="sns" element={<Sns setCrumbItem={setCrumbItem} />} />
                                <Route path="friend" element={<Friend setCrumbItem={setCrumbItem} />} />
                                <Route path="invitation" element={<Invitation setCrumbItem={setCrumbItem} />} />
                                <Route path="payment" element={<Payment setCrumbItem={setCrumbItem} />} />
                                <Route path="payment/:id" element={<PaymentDetail setCrumbItem={setCrumbItem} />} />
                                <Route path="support" element={<Support setCrumbItem={setCrumbItem} />} />
                            </Routes>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}