import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { ConvertToDisplayDate, number_format, PaymentCondition, PaymentMethod } from "../../controller/keys";
import Escape from "../../controller/Escape";

export default function Payment({setCrumbItem}){
    const {id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        Initialize();
    },[]);
    function Initialize(){
        let send={
            'url':'v1/payment/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']['histories']);
                setCrumbItem([data['payloads']['title'],[
                    {
                        index:0,
                        href:"/",
                        text:"ホーム"
                    },
                    {
                        index:1,
                        href:"/account",
                        text:"アカウント"
                    },
                    {
                        index:2,
                        href:"/account/payment",
                        text:"決済"
                    }
                ]]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        });
    }
    function Color(id){
        switch(id){
            case 0:
                return 'success';
                break;
            case 1:
                return 'info';
                break;
            case 2:
                return 'danger';
                break;
            case 3:
                return 'warning';
                break;
        }
    }
    function convertDescription(description){
        switch(description['type']){
            case 'link':
                return (<a href={description['link']} target="_blank">{description['title']}</a>);
                break;
            case 'text':
                return description['content'];
                break;
        }
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <Accordion defaultActiveKey="0">
                {Item.map(item=><Accordion.Item key={item['PaymentId']} eventKey={item['PaymentId']}>
                    <Accordion.Header>
                        {item['title']}
                        <span className={"btn btn-"+Color(item['condition'])+" mx-2"}>{PaymentCondition(item['condition'])}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th>
                                        決済タイトル
                                    </th>
                                    <td>
                                        {item['title']}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済内容
                                    </th>
                                    <td>
                                        {(item['description'] && item['description'].length) ? item['description'].map((description,index)=><li key={index}>{convertDescription(description)}</li>):""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        金額
                                    </th>
                                    <td>
                                        {number_format(item['price'])}円
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        予定決済日
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(item['ScheduledDate'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済日時
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(item['PaymentDatetime'],true)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済方法
                                    </th>
                                    <td>
                                        {PaymentMethod(item['method'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        備考
                                    </th>
                                    <td>
                                        {Escape(item['note'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        状態
                                    </th>
                                    <td>
                                        {PaymentCondition(item['condition'])}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>)}
            </Accordion>:""}
        </>
    );
}