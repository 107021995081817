import { useEffect, useState } from "react";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";

export default function Invitations(){
    const BCrumb={'CurrentText':'紹介制度','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/admin/invitation',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setAlert({'type':'danger','style':true,'msgs':['通信エラーが発生しました。']});
            setVisible(false);
            console.log(error)
        });
    },[])
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                紹介者
                            </th>
                            <th>
                                参加者
                            </th>
                            <th>
                                プラン
                            </th>
                            <th>
                                登録日時
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {Item[0] && 
                    Item[1].map(item=>
                        <tr key={item['InvitationId']}>
                            <td>
                                <a href={"/profile/"+item['Inviting']['UserId']} target="_blank">{item['Inviting']['UserName']}</a>
                            </td>
                            <td>
                                <a href={"/profile/"+item['Invited']['UserId']} target="_blank">{item['Invited']['UserName']}</a>
                            </td>
                            <td>
                                {item['plan']['PlanName']}
                            </td>
                            <td>
                                {item['InvitedDatetime']}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}