import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import Escape from "../../controller/Escape";
import { ContactType } from "../../controller/keys";

export default function InfoDetail({setCrumbItem}){
    const {id}=useParams();
    const [Contact,setContact]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/contact/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setCrumbItem([data['payloads']['ContactTitle'],[
                    {
                        index:0,
                        href:"/",
                        text:"ホーム"
                    },
                    {
                        index:1,
                        href:"/account",
                        text:"アカウント"
                    },
                    {
                        index:2,
                        href:"/account/info",
                        text:"お知らせ"
                    }
                ]]);
                setContact(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Contact ?
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                        {Contact['ContactTitle']}
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                            {Escape(Contact['ContactContent'])}
                            </div>
                            <div className="list-group">
                                {Contact['files'].map(file=>
                                    <a href={file['link']} className="list-group-item list-group-item-action" key={file['FileId']} target="_blank">
                                        {file['FileName']}
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                        <p className="m-0">
                            掲載日時：{Contact['PostedDatetime']}
                        </p>
                        <p className="m-0">
                            掲載者：{Contact['EntriedUserName']}
                        </p>
                        <p className="m-0">
                            カテゴリー：{ContactType(Contact['ContactType'])}
                        </p>{Contact['CourseName'] &&
                        <p className="m-0">
                            コース名：{Contact['CourseName']}
                        </p>}
                        </div>
                    </div>
                </div>
            </div>:""}
        </>
    );
}