import { useEffect, useState } from "react";
import { ConvertToDisplayDate, Domain } from "../../controller/keys";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";

export default function Courses(){
    const BCrumb={'CurrentText':'コース管理','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/admin/course',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                コース名
                            </th>
                            <th>
                                登録日
                            </th>
                            <th>
                                開講日
                            </th>
                            <th>
                                終了日
                            </th>
                            <th>
                                講師
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Item[0] && Item[1].map((course,index)=>
                        <tr key={index}>
                            <td>
                                <a href={Domain('course',course['CourseId'])} target="_blank">{course['CourseName']}</a>
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['EntriedDate'],true)}
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['term'][0])}
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['term'][1])}
                            </td>
                            <td>
                                {course['teachers'].map((teacher,index)=><li key={index}>
                                    <a href={"/profile/teacher/"+teacher['TeacherId']} target="_blank">
                                        {teacher['UserName']}
                                    </a>
                                </li>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function Course({Item}){
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>
                            コース名
                        </th>
                        <th>
                            登録日
                        </th>
                        <th>
                            開講日
                        </th>
                        <th>
                            終了日
                        </th>
                        <th>
                            講師
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Item.map((course,index)=>
                    <tr key={index}>
                        <td>
                            {course['CourseName']}
                        </td>
                        <td>
                            {course['EntriedDate']}
                        </td>
                        <td>
                            {course['term'][0]}
                        </td>
                        <td>
                            {course['term'][1]}
                        </td>
                        <td>
                            {course['teachers'].map((teacher,index)=><li key={index}>{teacher['UserName']}</li>)}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
}