import { useEffect, useState } from "react";
import {  Link } from "react-router-dom";
import { AdminInfoTo, AdminInfoType } from "../../controller/keys";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";

export default function Infos(){
    const BCrumb={'CurrentText':'お知らせ','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/admin/info',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <Link to="/admin/infos/entry" className="btn btn-primary">
                お知らせ配信
            </Link>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                タイトル
                            </th>
                            <th>
                                登録日時
                            </th>
                            <th>
                                配信種類
                            </th>
                            <th>
                                対象利用者
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Item[0] && 
                        Item[1].map(info=>
                        <tr key={info['InfoId']}>
                            <td>
                                <Link to={"/admin/infos/show/"+info['InfoId']}>
                                    {info['InfoTitle']}
                                </Link>
                            </td>
                            <td>
                                {info['PostedDate']}
                            </td>
                            <td>
                                {AdminInfoType(info['type'])}
                            </td>
                            <td>
                                {AdminInfoTo(info['to'])}
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}