import Crumb from "../../controller/Crumb";

export default function AdminHome(){
    return(
        <div>
            <Crumb CurrentText="管理者機能" items={[]} />
            <div>
                メニューを選択してください。
            </div>
        </div>
    );
}