import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Account from './view/Account';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Home from './view/Home';
import Line from './view/Line';
import Profile from './view/Profile';
import AdminHome from './view/admin/Home';
import Users from './view/admin/user';
import UserDetail from './view/admin/UserDetail';
import Courses from './view/admin/courses';
import Infos from './view/admin/infos';
import InfoEntry from './view/admin/InfoEntry';
import InfoDetail from './view/admin/InfoDetail';
import Templates from './view/admin/templates';
import Support from './view/admin/support';
import Invitations from './view/admin/invitations';
import Plans from './view/admin/plans';
import Payments from './view/admin/payments';

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main' role="main">
				<div className='container h-100'>
					<div className='row h-100'>
						<div className='col-12 h-100'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
									<Route path="account/*" element={<Account />} />
									<Route path='line' element={<Line />} />
									<Route path="profile/*" element={<Profile />} />
								</Route>
								<Route path='/admin'>
									<Route index element={<AdminHome />} />
									<Route path='users'>
										<Route index element={<Users />} />
										<Route path=':id' element={<UserDetail />} />
									</Route>
									<Route path='courses' element={<Courses />} />
									<Route path='infos'>
										<Route index element={<Infos />} />
										<Route path='entry' element={<InfoEntry />} />
										<Route path='show/:id' element={<InfoDetail />} />
									</Route>
									<Route path='template' element={<Templates />} />
									<Route path='support' element={<Support />} />
									<Route path='invitations' element={<Invitations />} />
									<Route path='plans' element={<Plans />} />
									<Route path='payments' element={<Payments />} />
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
