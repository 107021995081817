import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import { FriendCondition } from "../../controller/keys";

export default function Friend({setCrumbItem}){
    const [Friend,setFriend]=useState({applicating:[],applicated:[]});
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        setCrumbItem(['フレンド申請',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        let send={
            'url':'v1/friend',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setFriend(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);Initialize()});
    }
    function clickFriend(e,key,id){
        let send={
            'url':'v1/friend/'+id,
            'method':'PUT',
            'body':{
                'condition':key
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let friend=JSON.stringify(Friend);
                friend=JSON.parse(friend);
                friend['applicated'][e.target.parentNode.parentNode.dataset.id]['condition']=key;
                let name=friend['applicated'][e.target.parentNode.parentNode.dataset.id]['UserName'];
                setFriend(friend)
                setAlert({'type':'success','style':true,'msgs':[name+'の友達申請を'+FriendCondition(key)+'しました。']});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div>
                <h4 className="my-2">
                    申請済み友達一覧
                </h4>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                名前
                            </th>
                            <th>
                                状態
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Friend['applicating'].map((friend,index)=>
                        <tr key={index}>
                            <td>
                                <a href={"/profile/"+friend['UserId']} target="_blank">
                                    {friend['UserName']}
                                </a>
                            </td>
                            <td>{friend['condition']==0 ?
                                <a href={friend['qr']} target="_blank">
                                    QRコード表示
                                </a>:
                                <span>
                                    QRコードを表示できません
                                </span>
                                }
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                <h4 className="my-2">
                    友達提案一覧
                </h4>
                <p>
                    承諾すると，登録しているQRコードを相手が表示できるようになります。
                </p>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                名前
                            </th>
                            <th>
                                状態
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Friend['applicated'].map((friend,index)=>
                        <tr data-id={index} key={index}>
                            <td>
                                <a href={"/profile/"+friend['UserId']} target="_blank">
                                    {friend['UserName']}
                                </a>
                            </td>
                            <td>
                                {FriendCondition(friend['condition'])}{friend['condition']!=0 &&
                                <button type="button" className="btn btn-success ms-1" onClick={e=>clickFriend(e,0,friend['FriendId'])}>承諾</button>}{friend['condition']!=1 &&
                                <button type="button" className="btn btn-danger ms-1" onClick={e=>clickFriend(e,1,friend['FriendId'])}>拒否</button>}{friend['condition']!=2 &&
                                <button type="button" className="btn btn-secondary ms-1" onClick={e=>clickFriend(e,2,friend['FriendId'])}>無視</button>}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </>
    );
}