import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router";
import AlertBox from "../controller/AlertBox";
import Fetch from "../controller/Fetch";
import { getParams } from "../controller/keys";
import Loading from "../controller/Loading";

export default function Line(){

    const [Alert,setAlert]=useState();
	const [Visible,setVisible]=useState(true);

    const [cookies,setCookies,removeCookies]=useCookies();
    const [ToRedirect,setToRedirect]=useState(false);
    useEffect(()=>{
        let params = getParams();
        //ログイン処理
        let send={
            'url':'v1/line/'+cookies['nonce'],
            'method':'PUT',
            'body':params
        };
        Fetch(send).then(data=>{
            if(data['result'] && data['payloads']['access_token']){

                setTimeout(()=>{
                    setToRedirect('/account/sns');
                },3000);
            }else{
                setVisible(false);

                setAlert({'type':'danger','style':true,'msgs':["ログインできませんでした。","5秒後にログイン画面にリダイレクトします。"]});
                setTimeout(()=>{
                    setToRedirect('/account/sns');
                },5000);
            }
            removeCookies('nonce');
        })
        .catch((error)=>{
            setVisible(false);

            console.log(error);
        })
    },[]);
    return (
        <div>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            {ToRedirect?<Navigate to={ToRedirect} replace />:""}
        </div>
    );
}