import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ConvertToDisplayDate, number_format, PaymentCondition, UserRight, UserType } from "../../controller/keys";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";

export default function UserDetail(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/admin','text':'ホーム'},{index:2,href:'/admin/users',text:"会員管理"}]});
    const [Alert,setAlert]=useState();
    const [Data,setData]=useState({
        'UserName':'',
        'UserType':'',
        'UserRight':'',
        'payments':[]
    });
    useEffect(()=>{
        let send={
            'url':'v1/admin/user/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setData(data['payloads']);
                setBCrumb({
                    CurrentText: data['payloads']['UserName'],
                    items: [
                        {
                            index: 1,
                            href: "/admin",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/admin/users',
                            text:"会員管理"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    function doEntry(condition){
        setVisible(true);
        let send={
            'url':'v1/admin/user/'+id,
            'method':'PUT',
            'body':{
                'condition':condition
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["ユーザー権限を変更しました。"]});
                let old=JSON.parse(JSON.stringify(Data));
                old['UserRight']=condition;
                setData(old);
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)});
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>
                                名前
                            </th>
                            <td>
                                {Data['UserName']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                ユーザー種類
                            </th>
                            <td>
                                {UserType(Data['UserType'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                ユーザー権限
                            </th>
                            <td>
                                {UserRight(Data['UserRight'])}
                                {Data['UserRight']!=0 && 
                                <button type="button" className="btn btn-secondary ms-2" onClick={()=>doEntry(0)}>一般に変更</button>
                                }
                                {Data['UserRight']!=1 && 
                                //<button type="button" className="btn btn-secondary ms-2" onClick={()=>doEntry(1)}>利用料管理者に変更</button>
                                ""
                                }
                                {Data['UserRight']!=2 && 
                                <button type="button" className="btn btn-secondary ms-2" onClick={()=>doEntry(2)}>総合管理者に変更</button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="table-responsive">
                <h3>支払い履歴</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                決済名
                            </th>
                            <th>
                                金額
                            </th>
                            <th>
                                決済日
                            </th>
                            <th>
                                状態
                            </th>
                            <th>
                                備考
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Data['payments'].map((payment,index)=>
                        <tr key={index}>
                            <td>
                                {payment['title']}
                            </td>
                            <td>
                                {number_format(payment['price'])}円
                            </td>
                            <td>
                                {ConvertToDisplayDate(payment['PaymentDatetime'],true)}
                            </td>
                            <td>
                                {PaymentCondition(payment['condition'])}
                            </td>
                            <td>
                                {payment['note']}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}