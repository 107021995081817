import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import LineBtn from "../common/LineBtn";
import { useCookies } from "react-cookie";

export default function Sns({setCrumbItem}){
    const [SNS,setSNS]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
	const [cookies,setCookie,removeCookie]=useCookies();
    useEffect(()=>{
        setCrumbItem(['SNS連携',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/line',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setSNS(data['payloads']);
                if(data['payloads']['nonce']){
                    removeCookie('nonce');
                    setCookie('nonce',data['payloads']['nonce']);
                }
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            setVisible(false);
            console.log(error);
        });
    }
    function doEntry(){
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{SNS?
            <div>
                LINE:{SNS['connected']?'連携済み':<>未連携<LineBtn Link={SNS['link']} target="_blank" /></>}
            </div>:""}
        </>
    );
}