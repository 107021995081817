import { useEffect, useState } from "react";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function Payments(){
    const BCrumb={'CurrentText':'支払い設定','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/admin/account',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            console.log(error);
            setVisible(false);
        });
    },[])
    function doEntry(send){
        setVisible(true)
        let SendBody={};
        let AllError=[];
        //stripe
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-stripe'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'stripes':body});
        }
        //paypay
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-paypay'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'paypays':body});
        }
        //paypal
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-paypal'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'paypals':body});
        }

        if(AllError.length){
            setAlert({'type':'danger','style':true,'msgs':AllError});
            setVisible(false)
            return;
        }else{
            let send={
                'url':'v1/admin/account',
                'method':'POST',
                'body':SendBody
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["支払い方法を登録しました。"]});
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false)
            }).catch(error=>{console.log(error);setVisible(false)});
        }
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div>
                {Item[0] && 
                <form id="AdminAccountForm">
                    <AccountForm Item={Item[1]} Submit={doEntry} type="admin" />{/*
                    <table className="table table-striped table-hover">
                        <tbody>
                            <tr>
                                <th>
                                    Stripe
                                </th>
                                <td>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">手動決済</span>
                                    <select name="manual" className="form-select js-data-stripe" defaultValue={Item[1]['stripes']['manual']} data-type="boolean" required>
                                        <option value={true}>許可する</option>
                                        <option value={false}>許可しない</option>
                                    </select>
                                    </div>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        公開APIキー
                                    </span>
                                    <input type="text" name="api" className="form-control js-data-stripe" placeholder="公開APIキー" defaultValue={Item[1]['stripes']['api']} required />
                                    </div>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        シークレットキー
                                    </span>
                                    <input type="text" name="secret" className="form-control js-data-stripe" placeholder="シークレットキー" defaultValue={Item[1]['stripes']['secret']}  required />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            <th>
                                PayPay
                            </th>
                            <td>
                                <div className="my-2 input-group">
                                <span className="input-group-text">手動決済</span>
                                <select name="manual" className="form-select js-data-paypay" defaultValue={Item[1]['paypays']['manual']} data-type="boolean" required>
                                    <option value={true}>許可する</option>
                                    <option value={false}>許可しない</option>
                                </select>
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    APIキー
                                </span>
                                <input type="text" name="api" className="form-control js-data-paypay" placeholder="APIキー" defaultValue={Item[1]['paypays']['api']}  required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    シークレットキー
                                </span>
                                <input type="text" name="secret" className="form-control js-data-paypay" placeholder="シークレットキー" defaultValue={Item[1]['paypays']['secret']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    マーチャントID
                                </span>
                                <input type="text" name="merchant" className="form-control js-data-paypay" placeholder="マーチャントID" defaultValue={Item[1]['paypays']['merchant']} required />
                                </div>
                                <div className="card">
                                <div className="card-header">
                                    PayPay for Developersでの設定
                                </div>
                                <div className="card-body">
                                    <ol>
                                    <li>
                                        <a href="https://developer.paypay.ne.jp/settings" target="_blank">PayPay for Developers \ 構成</a>に移動
                                    </li>
                                    <li>
                                        コールバックドメインを<input type="text" defaultValue="canteach.jp" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        突合ファイル通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/file/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        カスタマーイベント通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/customer/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        決済トランザクション通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/transaction/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <th>
                                PayPal
                            </th>
                            <td>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    APIキー
                                </span>
                                <input type="text" name="api" className="form-control js-data-paypal" placeholder="APIキー" defaultValue={Item[1]['paypals']['api']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    シークレットキー
                                </span>
                                <input type="text" name="secret" className="form-control js-data-paypal" placeholder="シークレットキー" defaultValue={Item[1]['paypals']['secret']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    マーチャントID
                                </span>
                                <input type="text" name="merchant" className="form-control js-data-paypal" placeholder="マーチャントID" defaultValue={Item[1]['paypals']['merchant']} required />
                                </div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>*/}
                </form>
                }
            </div>
        </div>
    )
}
function AccountForm(props){
    let Account;
    if(props.Item===undefined){
        Account={
            "AccountName": "",
            "stripes": {
                "api": "",
                "secret": "",
                "manual":false
            },
            "paypays": {
                "api": "",
                "secret": "",
                "merchant": "",
                "manual":false
            },
            "paypals": {
                "api": "",
                "secret": "",
                "merchant": "",
                "manual":false
            }
        };
    }else{
        Account=props.Item;
    }
    function doEntry(){
        let send={
            'url':'v1/teacher/account',
            'method':'POST',
            'body':{}
        }
        let parent=document.getElementById('AccountForm');
        if(!(props.type && props.type=='admin')){
            send['body']['AccountName']=document.getElementById('AccountName').value;
        }
        send['body']['stripes']=SendJson(parent.getElementsByClassName('js-data-stripe'));
        send['body']['paypays']=SendJson(parent.getElementsByClassName('js-data-paypay'));
        send['body']['paypals']=SendJson(parent.getElementsByClassName('js-data-paypal'));
        props.Submit(send);
    }
    function select(e){
        e.target.select();
    }
    return(
        <form id="AccountForm">
            <table className="table table-striped table-hover">
                <tbody>{props.type && props.type=='admin'?'':
                    <tr>
                        <th>
                            登録名
                        </th>
                        <td>
                            <input type="text" name="AccountName" id="AccountName" className="form-control js-data" placeholder="登録名" defaultValue={Account['AccountName']} required />
                        </td>
                    </tr>}
                    <tr>
                    <th>
                        Stripe
                    </th>
                    <td>{/*
                        <div className="my-2 input-group">
                            <span className="input-group-text">手動決済</span>
                            <select name="manual" className="form-select js-data-stripe" defaultValue={Account['stripes']['manual']} data-type="boolean">
                                <option value={true}>許可する</option>
                                <option value={false}>許可しない</option>
                            </select>
                    </div>*/}
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            公開APIキー
                        </span>
                        <input type="text" name="api" className="form-control js-data-stripe" placeholder="公開APIキー" defaultValue={Account['stripes']['api']} />
                        </div>
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            シークレットキー
                        </span>
                        <input type="text" name="secret" className="form-control js-data-stripe" placeholder="シークレットキー" defaultValue={Account['stripes']['secret']}  />
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <th>
                        PayPay
                    </th>
                    <td>{/*
                        <div className="my-2 input-group">
                            <span className="input-group-text">手動決済</span>
                            <select name="manual" className="form-select js-data-paypay" defaultValue={Account['paypays']['manual']} data-type="boolean">
                                <option value={true}>許可する</option>
                                <option value={false}>許可しない</option>
                            </select>
                    </div>*/}
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            APIキー
                        </span>
                        <input type="text" name="api" className="form-control js-data-paypay" placeholder="APIキー" defaultValue={Account['paypays']['api']}  />
                        </div>
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            シークレットキー
                        </span>
                        <input type="text" name="secret" className="form-control js-data-paypay" placeholder="シークレットキー" defaultValue={Account['paypays']['secret']} />
                        </div>
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            マーチャントID
                        </span>
                        <input type="text" name="merchant" className="form-control js-data-paypay" placeholder="マーチャントID" defaultValue={Account['paypays']['merchant']} />
                        </div>
                        <div className="card">
                        <div className="card-header">
                            PayPay for Developersでの設定
                        </div>
                        <div className="card-body">
                            <ol>
                            <li>
                                <a href="https://developer.paypay.ne.jp/settings" target="_blank">PayPay for Developers \ 構成</a>に移動
                            </li>
                            <li>
                                コールバックドメインを<input type="text" defaultValue="app.canteach.jp" onFocus={select} readOnly />に設定
                            </li>
                            <li>
                                突合ファイル通知Webhookを<input type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={select} readOnly />に設定
                            </li>
                            <li>
                                カスタマーイベント通知Webhookを<input type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={select} readOnly />に設定
                            </li>
                            <li>
                                決済トランザクション通知Webhookを<input type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={select} readOnly />に設定
                            </li>
                            </ol>
                        </div>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <th>
                        PayPal
                    </th>
                    <td>
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            Client ID
                        </span>
                        <input type="text" name="client" className="form-control js-data-paypal" placeholder="Client ID" defaultValue={Account['paypals']['client']} />
                        </div>
                        <div className="my-2 input-group">
                        <span className="input-group-text">
                            Secret
                        </span>
                        <input type="text" name="secret" className="form-control js-data-paypal" placeholder="Secret" defaultValue={Account['paypals']['secret']} />
                        </div>{/*
                        <div className="my-2 input-group">
                            <span className="input-group-text">
                                マーチャントID
                            </span>
                            <input type="text" name="merchant" className="form-control js-data-paypal" placeholder="マーチャントID" defaultValue={Account['paypals']['merchant']} />
                        </div>*/}
                    </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{props.Delete &&
                <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>
                    削除
                </button>}
            </div>
        </form>
        );
}