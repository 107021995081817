import { useEffect, useRef, useState } from "react";
import Fetch from "../../controller/Fetch";
import Upload from "../../controller/Upload";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import { getUser } from "../..";
import SendJson from "../../controller/SendJson";
import { loadStripe } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethodImg, PaymentMethodKey, PaymentMethodLink } from "../../controller/keys";

export default function Profile({setCrumbItem}){
    const [Profile,setProfile]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    const [TeacherStyle,setTeacherStyle]=useState({display:'none'});
    const [Payment,setPayment]=useState(false);
    const [PaymentMethod,setPaymentMethod]=useState([]);
    const StripeToken=useRef();
    const [Plan,setPlan]=useState([]);
    useEffect(()=>{
        setCrumbItem(['プロフィール設定',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        const user=getUser();

        let send={
            'url':'v1/profile/'+user['UserId'],
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setProfile(data['payloads']);
                if(!data['payloads']['payment']){
                    getPaymentMethod();
                }else{
                    setVisible(false);
                }
                changeUserType();
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                setVisible(false);
            }
        }).catch(error=>{setVisible(false);console.log(error)});
        send={
            'url':'v1/admin/plan',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setPlan(data['payloads']);
            }else{
                console.log("can't get plans");
            }
        }).catch(error=>console.log(error))
    }
    function getPaymentMethod(){
        setVisible(true);
        let send={
            'url':'v1/teacher/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setPaymentMethod(data['payloads']);
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)})
    }
    function changeImg(e){
        let src=e.target.parentNode.parentNode.getElementsByClassName('js-img')[0];
        if(e.target.value){
            src.src=window.URL.createObjectURL(e.target.files[0]);
        }else{
            src.src=src.dataset.src;
        }
    }
    function changeUserType(){
        if(document.getElementById('UserType').value!=1){
            setTeacherStyle(true);
        }else{
            setTeacherStyle(false);
        }
    }
    function changePayment(e){
        setPayment(e.target.value);
    }
    function doEntry(){

		setVisible(true);

        let file=document.getElementsByClassName('js-data-file');
        let keys=[];
        let fd=new FormData();
        for(let i=0;i<file.length;++i){
            if(file[i].value){
                keys.push(i);
                fd.append('file[]',file[i].files[0]);
            }
        }
        if(keys.length){
            Upload(fd).then(data=>{
                if(data['result']){
                    let files=["",""];
                    for(let i=0;i<keys.length;++i){
                        files[keys[i]]=data['payloads']['FileIds'][i];
                    }
                    doProfileSubmit(files);
                }else{
                    setVisible(false);
    
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>{
                console.log(error)
                setVisible(false);
            });
        }else{
            doProfileSubmit(['','']);
        }
    }
    function doProfileSubmit(files){

        let send={
            'url':'v1/profile',
            'method':'POST',
            'body':SendJson(document.getElementsByClassName('js-data'))
        }
        send['body']['icon']=files[0];
        send['body']['qr']=files[1];
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','msgs':["プロフィールを変更しました。"]});
                Initialize();
            }else{
                setVisible(false);

                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)
            setVisible(false);
        });
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Profile ?
            <div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        メールアドレス
                    </label>
                    <div className="col-sm-10">
                        <input type="email" name="email" className="form-control js-data" placeholder="メールアドレス" defaultValue={Profile['email']} required />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        名前
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="UserName" className="form-control js-data" placeholder="名前" defaultValue={Profile['UserName']} required />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        アイコン
                    </label>
                    <div className="col-sm-auto">
                        <img src={Profile['icon']} data-src={Profile['icon']} className="files__icon-lg js-img my-2" />
                    </div>
                    <div className="col-sm-auto">
                        <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeImg} />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        ステータスメッセージ
                    </label>
                    <div className="col-sm-10">
                        <textarea name="introduction" className="form-control js-data" placeholder="ステータスメッセージ" defaultValue={Profile['introduction']} />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        LINE QRコード
                    </label>
                    <div className="col-sm-auto">
                        <img src={Profile['qr']} data-src={Profile['qr']} className="files__icon-lg js-img my-2" />
                    </div>
                    <div className="col-sm-auto">
                        <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeImg} />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        ホームページ
                    </label>
                    <div className="col-sm">
                        <input type="url" name="hp" className="form-control js-data" placeholder="ホームページ" defaultValue={Profile['hp']} />
                    </div>
                    <div className="col-sm-auto">
                        <select name="HpPublic" className="form-select js-data" defaultValue={Profile['HpPublic']} data-type="boolean">
                            <option value={true}>公開</option>
                            <option value={false}>非公開</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        Twitter
                    </label>
                    <div className="col-sm">
                        <div className="input-group">
                            <span className="input-group-text">@</span>
                            <input type="text" name="twitter" className="form-control js-data" placeholder="Twitter" defaultValue={Profile['twitter']} />
                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <select name="TwitterPublic" className="form-select js-data" defaultValue={Profile['TwitterPublic']} data-type="boolean">
                            <option value={true}>公開</option>
                            <option value={false}>非公開</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2">
                    <label className="col-sm-2 col-form-label">
                        ユーザー種類
                    </label>
                    <div className="col-sm-10">
                        <select name="UserType" id="UserType" className="form-control js-data" defaultValue={Profile['UserType']} onChange={changeUserType} required>
                            <option value={0}>受講者・講師</option>
                            <option value={1}>受講者</option>
                            <option value={2}>講師</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2" style={{display:(TeacherStyle?'flex':'none')}}>
                    <label className="col-sm-2 col-form-label">
                        講師プラン
                    </label>
                    <div className="col-sm-10">
                        <select name="plan" className="form-select js-data" defaultValue={Profile['plan']}>{Plan.map((plan,index)=>
                        <option value={plan['PlanId']} key={index}>{plan['PlanName']}({plan['price']}円)</option>
                        )}</select>
                    </div>
                </div>{Profile['payment']?'':
                <div className="row my-2" style={{display:(TeacherStyle?'flex':'none')}}>
                    <label className="col-sm-2 col-form-label">
                        お支払い方法
                    </label>
                    <div className="col-sm-10">
                        <select name="PaymentMethod" className={"form-select"+(TeacherStyle?" js-data":"")} onChange={changePayment} required={TeacherStyle}>
                            <option label="選択してください" style={{display:"none"}}></option>{PaymentMethod.map(method=>
                            <option value={method['method']} key={method['method']}>{PaymentMethodKey(method['method'])}</option>)}
                        </select>
                        <div className="row mt-2" style={{maxWidth:'700px'}}>{PaymentMethod.map(method=>
                            <div className="col-sm-3">
                                <a href={PaymentMethodLink(method['method'])} target="_blank">
                                    <img src={PaymentMethodImg(method['method'])} alt={PaymentMethodKey(method['method'])} className="w-100 h-100" />
                                </a>
                            </div>)}
                        </div>{PaymentMethod.map(method=>method['method']==='stripe'?Payment=='stripe'?
                        <div>
                            <input type="hidden" name="StripeToken" className="js-data" ref={StripeToken} />
                            <Stripe PublicKey={method['apiKey']} Token={StripeToken} />
                        </div>:"":"")}
                    </div>
                </div>}
                <div className="row mt-2">
                    <div className="col">
                        <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>
                    </div>
                </div>
            </div>:""}
        </>
    );
}
let stripePromise;
function Stripe({PublicKey,Token}){
    stripePromise=loadStripe(PublicKey);
    return(
        <>{PublicKey?
            <Elements stripe={stripePromise}>
                <StripeElement Token={Token} />
            </Elements>:
        ''}
        </>
    );
}
function StripeElement({Token}){
    const stripe=useStripe();
    const elements=useElements();

    const [Alert,setAlert]=useState(false);
    const [Card,setCard]=useState({cardNumber:false,cardExpiry:false,cardCvc:false});//cardNumber,expiry,cvc
    const [Errors,setErrors]=useState({cardNumber:'',cardExpiry:'',cardCvc:''});
	const [Visible,setVisible]=useState(false);

    function displayCardError(e){
        changeError(e);
    }
    function displayExpiryError(e){
        changeError(e);
    }
    function displayCvcError(e){
        changeError(e);
    }
    function checkCard(card){
        setCard(card);
        if(!card['cardNumber']){
            elements.getElement('cardNumber').focus();
        }else if(!card['cardExpiry']){
            elements.getElement('cardExpiry').focus();
        }else if(!card['cardCvc']){
            elements.getElement('cardCvc').focus();
        }else if(card['cardNumber'] && card['cardExpiry'] && card['cardCvc']){
            doEntry();
        }
    }
    function changeError(e){
        let error={};
        let card={};
        error[e.elementType]=e.error?e.error.message:false;
        card[e.elementType]=!e.error;
        if(e.complete){
            checkCard(Object.assign(Card,card));
            setErrors(Object.assign(Errors,error));
        }else{
            checkCard(Object.assign(Card,card));
            error=Object.assign(Errors,error);
            setErrors(error);
        }
        let check=(error['cardNumber'] || error['cardExpiry'] || error['CardCvc']);
        setAlert({'type':'danger','style':true,'msgs':check?[Errors['cardNumber'],Errors['cardExpiry'],Errors['cardCvc']]:[""]});
        Token.current.value="";
    }
    function doEntry(){
        setVisible(true);
        if(!stripe || !elements){
            setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
            Token.current.value="";
            setVisible(false);
        }else{
            /*stripe.createSource(elements.getElement('cardNumber'),{
                type:'card',
                currency:'jpy'
            })*/
            stripe.createPaymentMethod({
                type:'card',
                card:elements.getElement('cardNumber')
            }).then(result=>{
                if(result.error){
                    setAlert({'type':'danger','style':true,'msgs':[result.error.message]});
                }else{
                    setAlert({'type':'success','style':true,'msgs':["カードを利用できます。"]});
                    Token.current.value=result.paymentMethod.id;
                }
                setVisible(false);
            }).catch(error=>{
                setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
                console.log(error)
                Token.current.value="";
                setVisible(false);
            });
        }
    }
    return(
        <form id="StripeForm" className="my-2">
            <Loading visible={Visible} msgs={[]} />
            <div className="input-group mb-2">
                <span className="input-group-text">カード番号</span>
                <CardNumberElement id="card" className="form-control" onChange={displayCardError} options={{showIcon:true,iconStyle:'default'}} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">有効期限</span>
                <CardExpiryElement className="form-control" onChange={displayExpiryError} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">セキュリティーコード</span>
                <CardCvcElement className="form-control" onChange={displayCvcError} />
            </div>{/*
            <div className="my-2">
                <label>
                    <input type="checkbox" name="StripeRemember" className="form-check-input js-data" value="true" data-type="boolean" />
                    カード情報を紐づける
                </label>
            </div>*/}
            <div className="mt-2">
                カード情報は<a href="https://stripe.com" target="_blank">Stripe</a>に送信され，CanTeachのサーバーには送信されません。<br/>
                支払いのため，カード情報はStripeに保存されます。
            </div>
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
        </form>
    );
}