import { useEffect, useState } from "react";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function Plans(){
    const BCrumb={'CurrentText':'プラン設定','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        getPlans();
    },[])
    function getPlans(){
        let send={
            'url':'v1/admin/plan',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    }
    function addPlan(){
        let item=JSON.stringify(Item);
        item=JSON.parse(item);
        item[1].push({
            'PlanId':0,
            'PlanName':'新規プラン',
            'display':true,
            'price':1000,
            'MaxCourse':10,
            'MaxStudent':50,
            'web':true,
            'logo':true,
            'report':true,
            'test':true,
            'ad':true,
            'invite':true,
            'serma':true
        });
        setItem(item);
    }
    function doEntry(){
        setVisible(true);
        let row=document.getElementById('AdminPlanForm').getElementsByClassName('js-data-row');
        let SendBody=[];
        let AllError=[];
        for(let i=0;i<row.length;++i){
            let {error,body}=SendJson(row[i].getElementsByClassName('js-data'),true);
            if(error.length){
                AllError=Object.assign(AllError,error);
            }else{
                SendBody.push(Object.assign({'PlanId':row[i].dataset.id},body,SendJson(document.getElementById('PlanFunc_'+row[i].dataset.id).getElementsByClassName('js-data'))));
            }
        }
        
        if(AllError.length){
            setAlert({'type':'danger','style':true,'msgs':AllError});
            setVisible(false);
            return;
        }else{
            let send={
                'url':'v1/admin/plan',
                'method':'POST',
                'body':SendBody
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["プラン内容を登録しました。"]});
                    getPlans();
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <form id="AdminPlanForm">
                <h3>プラン概要</h3>
                <div className="table-responsive p-2">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    プラン名
                                </th>
                                <th>
                                    月額料金
                                </th>
                                <th>
                                    表示
                                </th>
                                <th>
                                    最大講座数
                                </th>
                                <th>
                                    最大受講者数/1講座
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Item[0] && Item[1].map(plan=>
                            <PlanOutline plan={plan} />
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2">
                                    <button type="button" className="btn btn-success" onClick={addPlan}>プラン追加</button>
                                </td>
                                <td colSpan={3} className="text-end">
                                    ※月額料金は登録後に変更できません。
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <h3>機能有無</h3>
                <div className="table-responsive p-2">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    Webサイト
                                </th>
                                <th>
                                    Webサイトロゴ表示
                                </th>
                                <th>
                                    課題
                                </th>
                                <th>
                                    テスト
                                </th>
                                <th>
                                    広告表示
                                </th>
                                <th>
                                    紹介特典
                                </th>
                                <th>
                                    SerMa
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {Item[0] && Item[1].map(plan=>
                            <PlanFunc plan={plan} />
                        )}
                        </tbody>
                    </table>
                </div>
                <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>
            </form>
        </div>
    )
}

function PlanOutline({plan}){
    return(
        <tr key={plan['PlanId']} className="js-data-row" data-id={plan['PlanId']}>
            <td>
                <input type="text" name="PlanName" className="form-control js-data" placeholder="プラン名" defaultValue={plan['PlanName']} required />
            </td>
            <td>
                <div className="input-group">
                    <input type="number" name="price" className="form-control js-data" placeholder="月額料金" defaultValue={plan['price']} required readOnly={plan['PlanId']?true:false} />
                    <span className="input-group-text">円</span>
                </div>
            </td>
            <td>
                <select name="display" data-type="boolean" className="form-select js-data" defaultValue={plan['display']}>
                    <option value={true}>表示する</option>
                    <option value={false}>表示しない</option>
                </select>
            </td>
            <td>
                <div className="input-group">
                    <input type="number" name="MaxCourse" className="form-control js-data" placeholder="最大講座数" defaultValue={plan['MaxCourse']} required />
                    <span className="input-group-text">講座</span>
                </div>
            </td>
            <td>
                <div className="input-group">
                    <input type="number" name="MaxStudent" className="form-control js-data" placeholder="最大受講者数/1講座" defaultValue={plan['MaxStudent']} />
                    <span className="input-group-text">名</span>
                </div>
            </td>
        </tr>
    );
}

function PlanFunc({plan}){
    function changeColor(e){
        if(e.target.value=='true'){
            e.target.classList.add('text-success');
            e.target.classList.remove('text-danger');
        }else{
            e.target.classList.remove('text-success');
            e.target.classList.add('text-danger');
        }
    }
    return (
        <tr id={"PlanFunc_"+plan['PlanId']} key={plan['PlanId']} data-id={plan['PlanId']}>
            <td>
                <select name="web" data-type="boolean" className={"form-select js-data "+(plan['web']?'text-success':'text-danger')} defaultValue={plan['web']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="logo" data-type="boolean" className={"form-select js-data "+(plan['logo']?'text-success':'text-danger')} defaultValue={plan['logo']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="report" data-type="boolean" className={"form-select js-data "+(plan['report']?'text-success':'text-danger')} defaultValue={plan['report']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="test" data-type="boolean" className={"form-select js-data "+(plan['test']?'text-success':'text-danger')} defaultValue={plan['test']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="ad" data-type="boolean" className={"form-select js-data "+(plan['ad']?'text-success':'text-danger')} defaultValue={plan['ad']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="invite" data-type="boolean" className={"form-select js-data "+(plan['invite']?'text-success':'text-danger')} defaultValue={plan['invite']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
            <td>
                <select name="serma" data-type="boolean" className={"form-select js-data "+(plan['serma']?'text-success':'text-danger')} defaultValue={plan['serma']} onChange={changeColor}>
                    <option value={1} className="text-success">有</option>
                    <option value={0} className="text-danger">無</option>
                </select>
            </td>
        </tr>
    );
}