import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import { UserRight, UserType } from "../../controller/keys";

export default function Users(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/admin/user',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem([true,data['payloads']])
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    return(
        <div>
            <Crumb CurrentText={'会員管理'} items={[{'index':1,'href':'/admin','text':'ホーム'}]} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ユーザー名</th>
                            <th>ユーザー種類</th>
                            <th>ユーザー権限</th>
                            <th>システム利用料未払い</th>
                        </tr>
                    </thead>
                    <tbody>{Item[0] && Item[1].map((user,index)=>
                        <tr key={index}>
                            <td>
                                <Link to={"/admin/users/"+user['UserId']}>{user['UserName']?user['UserName']:'No Name'}</Link>
                            </td>
                            <td>
                                {UserType(user['UserType'])}
                            </td>
                            <td>
                                {UserRight(user['UserRight'])}
                            </td>
                            <td>
                                {user['unpay'].map((unpay,index)=>
                                <span key={index}>{unpay}</span>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}