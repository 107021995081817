import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";

export default function Invitation({setCrumbItem}){
    const [Inviting,setInviting]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        setCrumbItem(['お友達招待',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/invitation',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let inviting=data['payloads'];
                inviting['message']=data['payloads']['UserName']+"さんがあなたCanTeachを招待しました。\nCanTeachに登録して，下記の招待コードを入力しよう！\n招待コード："+data['payloads']['InvitedCode']+"\n\nCanTeachの登録は\nhttps://app.canteach.jp/login/\n\n ==========\nCanTeachとは？\nhttps://canteach.jp/";
                setInviting(inviting);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);
        setVisible(false)});
    }
    function entryInvitation(){
		setVisible(true);
        let send={
            'url':'v1/invitation',
            'method':'POST',
            'body':{
                'InvitedCode':document.getElementById('InvitationCode').value
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setVisible(false);

                setAlert({'type':'success','style':true,'msgs':["招待コードを登録しました。"]});
                let invitation=JSON.stringify(Inviting);
                invitation=JSON.parse(invitation);
                invitation['EntriedCode']=true;
                setInviting(invitation);
            }else{

                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)
            setVisible(false);
        });
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Inviting?
            <div>{!Inviting['EntriedCode'] &&
                <div>
                    <h4>
                        招待コードの入力
                    </h4>
                    <div className="input-group my-2">
                        <input type="text" id="InvitationCode" className="form-control" placeholder="招待コード" />
                        <button type="button" className="btn btn-primary" onClick={entryInvitation}>登録</button>
                    </div>
                </div>
                }
                <h4 className="my-2">
                    お友達を招待
                </h4>
                <textarea className="form-control" style={{height:'265px'}} defaultValue={Inviting['message']} onFocus={e=>e.target.select()} />
                <h4 className="my-2">
                    参加友達
                </h4>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                名前
                            </th>
                            <th>
                                プラン
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Inviting['users'].map((inviting,index)=>
                        <tr key={index}>
                            <td>
                                <a href={"/profile/"+inviting['UserId']} target="_blank">
                                    {inviting['UserName']}
                                </a>
                            </td>
                            <td>
                                {inviting['plan']}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>:""}
        </>
    );
}