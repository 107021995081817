import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AdminInfoTo, AdminInfoType } from "../../controller/keys";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import Escape from "../../controller/Escape";

export default function InfoDetail(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/admin','text':'ホーム'},{'index':2,'href':'/admin/infos','text':'お知らせ'}]});
    const [Alert,setAlert]=useState();
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/admin/info/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                setBCrumb({
                    CurrentText: data['payloads']['InfoTitle'],
                    items: [
                        {
                            index: 1,
                            href: "/admin",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/admin/infos',
                            text:"お知らせ"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                        {Item['InfoTitle']}
                        </div>
                        <div className="card-body">
                        {Escape(Item['InfoContent'])}
                        </div>
                        <div className="card-footer">
                            <p className="m-0">
                                掲載日時：{Item['PostedDate']}
                            </p>
                            <p className="m-0">
                                配信種類：{AdminInfoType(Item['type'])}
                            </p>
                            <p className="m-0">
                                対象利用者：{AdminInfoTo(Item['to'])}
                            </p>
                        </div>
                    </div>
                </div>
            </div>:""}
        </div>
    )
}