import { useState } from "react";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function InfoEntry(){
    const BCrumb={
        CurrentText: "お知らせ配信",
        items: [
            {
                index: 1,
                href: "/admin",
                text: "ホーム"
            },
            {
                index:2,
                href:'/admin/infos',
                text:"お知らせ"
            }
        ],
    };
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(false);
    function doEntry(){
        setVisible(true);
        let {error,body}=SendJson(document.getElementById('AdminInfoForm').getElementsByClassName('js-data'),true);
        if(error.length){
			setAlert({'type':'danger','style':true,'msgs':error});
            setVisible(false)
			return;
		}else{
            let send={
                'url':'v1/admin/info',
                'method':'POST',
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["登録しました。"]});
                    document.getElementById('AdminInfoForm').remove();
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false)
            }).catch(error=>{console.log(error);setVisible(false)});
        }
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <form id="AdminInfoForm">
                <div className="row my-2">
                    <div className="col-sm-2">
                        種類
                    </div>
                    <div className="col-sm-10">
                        <select name="type" className="form-select js-data" required>
                            <option value="0">システムからのお知らせ</option>
                            <option value="1">運営からのお知らせ</option>
                            <option value="2">広告</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-sm-2">
                        対象利用者
                    </div>
                    <div className="col-sm-10">
                        <select name="to" className="form-select js-data" required>
                            <option value="0">全ユーザー</option>
                            <option value="1">受講者ユーザー</option>
                            <option value="2">講師ユーザー</option>
                            <option value="3">受講者のみユーザー</option>
                            <option value="4">講師のみユーザー</option>
                            <option value="5">受講者・講師ユーザー</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-sm-2">
                        タイトル
                    </div>
                    <div className="col-sm-10">
                        <input type="text" name="InfoTitle" className="form-control js-data" placeholder="タイトル" required />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-sm-2">
                        内容
                    </div>
                    <div className="col-sm-10">
                        <textarea name="InfoContent" className="form-control js-data" placeholder="内容" required />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>
                    </div>
                </div>
            </form>
        </div>
    )
}