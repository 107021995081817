import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import { Link } from "react-router-dom";

export default function Payment({setCrumbItem}){
    const [Payment,setPayment]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        setCrumbItem(['決済',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setPayment(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);
        setVisible(false)});
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Payment?
            <div>
                <h4 className="my-2">
                    決済履歴
                </h4>
                <div className="list-group">{Payment.map(payment=>
                    <Link to={"/account/payment/"+payment['id']} className="list-group-item list-group-item-action" key={payment['id']}>
                        {payment['title']}
                    </Link>
                )}
                </div>
            </div>:""}
        </>
    );
}