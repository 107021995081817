import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import { Accordion } from "react-bootstrap";
import Escape from "../../controller/Escape";

export default function Support({setCrumbItem}){
    const [support,setSupport]=useState();
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        setCrumbItem(['サポート',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        Initialize();
    },[]);
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/support',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setSupport(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    }
    function doSupport(){
		setVisible(true);
        let inputs=document.getElementById('AccountSupportForm').getElementsByClassName('js-data');
        let {error,body}=SendJson(inputs,true);
        if(error.length){
			setAlert({'type':'danger','style':true,'msgs':error});
			setVisible(false);

			return;
		}else{
            let send={
                'url':'v1/support',
                'method':'POST',
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setVisible(false);
    
                    setAlert({'type':'success','style':true,'msgs':["登録しました。"]});
                    inputs[0].value="";
                    inputs[1].value="";
                    Initialize();
                }else{
                    setVisible(false);
    
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
            }).catch(error=>{
                console.log(error)
                setVisible(false);
            });
        }
    }
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{support?
            <div>
                <div className="my-2">
                    CanTeachの操作方法が不明な場合，<a href="/support" target="_blank">よくある質問</a>をご確認ください。<br />
                    それでもわからない場合，下記より運営にご質問ください。<br />
                    なお，コースに関する質問は受け付けておりませんので各講師にご連絡ください。
                </div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item>
                        <Accordion.Header>
                            質問する
                        </Accordion.Header>
                        <Accordion.Body>
                            <form id="AccountSupportForm">
                            <div className="row my-2">
                                <div className="col-sm-2">
                                    タイトル
                                </div>
                                <div className="col-sm-10">
                                    <input type="text" name="SupportTitle" className="form-control js-data" placeholder="タイトル" required />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-sm-2">
                                    質問内容
                                </div>
                                <div className="col-sm-10">
                                    <textarea name="SupportContent" className="form-control js-data" placeholder="質問内容" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <button type="button" className="btn btn-primary" onClick={doSupport}>登録</button>
                                </div>
                            </div>
                        </form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="my-2">
                    <h3 className="my-2">
                        過去の質問内容
                    </h3>
                    <Accordion defaultActiveKey="0">
                    {support.map(support=>
                        <Accordion.Item key={support['SupportId']} eventKey={support['SupportId']}>
                            <Accordion.Header>
                                {support['SupportTitle']}
                            </Accordion.Header>
                            <Accordion.Body>
                                <h4 className="my-1">
                                    質問内容
                                </h4>
                                <div className="my-1">
                                    {Escape(support['content'])}
                                </div>
                                <h4 className="my-1">
                                    回答内容
                                </h4>
                                <div className="my-1">
                                    {Escape(support['answer'])}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>)}
                    </Accordion>
                </div>
            </div>:""}
        </>
    );
}