import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";

export default function Info({setCrumbItem}){
    const [Contact,setContact]=useState([]);
    const [Alert,setAlert]=useState(false);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        setCrumbItem(['お知らせ',[
            {
                index:0,
                href:"/",
                text:"ホーム"
            },
            {
                index:1,
                href:"/account",
                text:"アカウント"
            }
        ]]);
        let send={
            'url':'v1/contact',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setContact(data['payloads']['lists']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{setVisible(false);console.log(error)});
    },[]);
    return (
        <>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="list-group">{Contact.map((contact,index)=>
                <Link to={contact['ContactId']} className="list-group-item list-group-item-action contact__list-link" data-read={contact['condition']} key={index}>
                    {contact['ContactTitle']}
                </Link>)}
            </div>
        </>
    );
}