import { Link } from "react-router-dom";
import { getRights, getUser } from "..";
import { Domain } from "../controller/keys";

export default function Home(){
    const user=getUser();
    const rights=getRights();
    return (
        <section className="mt-4 pt-1">
            <h2 className="display-1 text-center">
                Welcome to <span className="text-basic">CanTeach</span>!
            </h2>
            <div className="my-3 text-center">
                <p className="fs-3">
                いつでもどこでも自由な学びを
                </p>
                <nav className="container mt-5">
                    <div className="row justify-content-center">{(user['UserType']==0 || user['UserType']==1) ?
                        <a href={Domain("student")} className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-basic">
                                <i className="fas fa-school d-block my-4 home__icon"></i>
                                <span className="fs-4">学ぶ</span>
                            </div>
                        </a>:''}{(user['UserType']==0 || user['UserType']==2) ? 
                        <a href={Domain("teacher")} className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-sub">
                                <i className="fas fa-chalkboard-teacher d-block my-4 home__icon"></i>
                                <span className="fs-4">教える</span>
                            </div>
                        </a>:''}{(rights['serma'] && (user['UserType']==0 || user['UserType']==2)) ? 
                        <a href={Domain("setting.serma")} className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-hilight">
                                <i className="far fa-calendar-alt d-block my-4 home__icon"></i>
                                <span className="fs-4">SerMa</span>
                            </div>
                        </a>:''}{(rights['web'] && (user['UserType']==0 || user['UserType']==2)) ? 
                        <a href={Domain("setting.lp")} className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-sub">
                                <i className="fab fa-internet-explorer d-block my-4 home__icon"></i>
                                <span className="fs-4">LP</span>
                            </div>
    </a>:''}
                        <Link to="/account" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale">
                            <div className="border border-2 shadow-sm m-2 p-1 text-gray">
                                <i className="fas fa-user-cog d-block my-4 home__icon"></i>
                                <span className="fs-4">アカウント</span>
                            </div>
                        </Link>{user['UserRight']!=0 ?
                        <Link to="/admin" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale">
                            <div className="border border-2 shadow-sm m-2 p-1 text-gray">
                                <i className="fas fa-cog d-block my-4 home__icon"></i>
                                <span className="fs-4">管理者機能</span>
                            </div>
                        </Link>:''}
                    </div>
                </nav>
            </div>
        </section>
    );
}